@import '@revfluence/widgets/lib/styles/base';
@import '@revfluence/widgets/lib/styles/colors';

.page { 
    margin: auto;
    padding-top: px(64);
    max-width: px(600);

    .title {
        @extend %Headline-4-Style;
        margin: px(48) 0;
    }

    .note {
        @extend %Body-1-Style;
        margin-bottom: px(48);
    }
}