@import "@revfluence/widgets/lib/styles/base";
@import "@revfluence/widgets/lib/styles/colors";

.page {
    margin: auto;
    padding-top: px(64);
    padding-left: px(24);
    padding-right: px(24);
    max-width: px(600);

    .title {
        @extend %Headline-6-Style;
        margin-bottom: px(12);
        margin-top: 0px;
    }

    .note {
        @extend %Body-1-Style;
        margin-bottom: px(48);
    }

    .divider {
        width: 100%;
        border-bottom: px(1) solid $border;
        margin: px(16) 0;
    }

    .terms {
        @extend %Body-1-Style;
        margin: px(32) 0;
        display: flex;

        span {
            margin-left: px(8);
        }
    }

    .error {
        @extend %Body-1-Style;
        color: $red;
    }

    .withDividers {
        border-bottom: px(1) solid $border;
        padding: px(30) 0;
    }

    .fieldContainer {
        padding: px(18) 0;

        .label {
            margin-bottom: px(16);
            @extend %Subtitle-2-Style;
        }

        .value {
            @extend %Body-2-Style;
        }
    }

    .errorMessage {
        margin-top: 7px;
        color: $watermelon;
        font-size: 13px;
    }

    .actionsContainer {
        padding: px(30) 0;

        .mainAction {
            margin-right: px(16);
            margin-bottom: px(16);
        }

        @media only screen and (max-width: px(400)) {
            .mainAction,
            .secondaryAction {
                width: 100%;
            }
        }
    }

    .select > div {
        background-color: $ice-blue !important;
    }

    .antd-select {
      width: 100%;
        &.ant-select-single {
            .ant-select-selection-item {
                line-height: px(38) !important;
            }
        }
        .ant-select-selector {
            line-height: px(34);
            height: px(38) !important;
            background-color: $ice-blue !important;
            border-radius: px(50) !important;
            border: none !important;
        }

        &.hasError {
            border: 1px solid $watermelon !important;
            border-radius: px($border-radius-max);

            .ant-select-selector {
                background-color: $light-pink !important;
            }

            .input {
                color: $red !important;

                &::placeholder {
                    color: $watermelon !important;
                    opacity: 0.8;
                }
            }

            .icon > svg {
                color: $red !important;
            }
        }
    }

    .required {
        color: $red;
    }
}
